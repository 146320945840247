import { axios } from "../../lib/axios";
import { useMutation } from "react-query";

export type RenewalsDTO = {
  first_name: string;
  last_name: string;
  home_address_or_policy_number: string;
  email: string;
  phone: string;
  is_renew_same: boolean;
  other?: string;
};

const createRenewals = (data: RenewalsDTO): Promise<any> => {
  return axios.post("/renewals", data);
};

export const useRenewals = () => {
  return useMutation({
    mutationFn: createRenewals,
  });
};
