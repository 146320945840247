import React from 'react'
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { SEO } from '../components/seo'
import Footer from "../components/footer"
import NavBar from "../components/header"
import { Container, VStack, Text, SimpleGrid, Box, Button, Image, HStack } from '@chakra-ui/react'
const aboutUsPage = (props: any) => {
  const employeesContentful = props.data.allContentfulEmployees.edges;
  return <>
    <SEO title="About Us | Acclaimed Home Warranty" description="Acclaimed Home Warranty - About Us" />
    <NavBar />
    <main>
      <div className="w-full" style={{ overflow: "visible" }}>
        <Container maxW={['lg', '8xl']} pt="12" position="relative">
          <HStack w="full" justifyContent="center">
            <Box w={{base:"100px", md:"150px", xl: "260px"}} h={{base:"100px", md:"150px", xl: "260px"}} display={{ base: "none", md: "block" }} background="#81e1e5" borderRadius="100%" position="absolute" bottom="-45px" left="80px" zIndex="-100">
              <Box 
              w={{base:"150px", lg:"200px", xl: "370px"}}
              right={{ base:"-40px", lg: "-118px"}}
              style={{
                position: "absolute",
                              
                bottom: "41px"
              }}>
                <StaticImage src="../images/norman-family.png" alt="AcclaimedHW contact us" />
              </Box>
            </Box>
            <VStack >
              <Text fontFamily="League Spartan, sans-serif" textAlign="center" fontSize={{ base: '3xl', sm:"3xl", md: '3xl', lg:"4xl", xl: '7xl' }} textColor="#01a1ba" fontWeight="bold">About Acclaimed <br /> Home Warranty</Text>
              <Text fontFamily="'League Spartan', sans-serif" fontSize={{ base: 'md', sm:'lg', md: 'xl', lg:"2xl", xl:'3xl' }} fontStyle="italic" pb="12px">  Family Owned and operated! </Text>
            </VStack>
            <Box w={{base:"200px", lg:"230px", xl: "360px"}} h={{base:"170px", lg:"200px",  xl:"330px"}} display={{ base: "none", md: "block" }} background="#81e1e5" borderRadius="100%" position="absolute" bottom="-45px" right="22px" zIndex="-100">

            </Box>

          </HStack>
        </Container>
        <Box w="full" h="10" background="#beeb9e" py="2">
        </Box>
      </div>
      {/** LEADERSHIP */}
      <Box background="#fff" px={{base:"15px", md: "50px"}} py="20px">
        <Container maxW={{base: "100%", lg:"8xl"}} mt="20px" mb="0">
          <VStack>
            <Text fontSize={{ base:'xl', sm:'xl', md:'2xl', lg: "3xl"}} px="12" backgroundColor="#6adce1" rounded="3xl">
              MEET OUR TEAM!
            </Text>

            <SimpleGrid columns={{ base: 2, sm:2, md: 3, xl: 6}} spacing="30px" marginY="40px">
              {employeesContentful.map((employee: any, index: any) => (
                <Box padding={{base:'5px', sm:"20px", md: "40px"}} key={index}>
                  <Image objectFit="cover" margin="auto" width={{base:"70px", sm:"100px", md: "100px"}} height={{base:"70px", sm:"100px", md: "100px"}} borderRadius="50%" src={employee.node?.photo?.url} alt={employee.node.name} />
                  <VStack color="#747474" spacing="5px" marginTop="30px">
                    <Text fontWeight="bold" fontSize="15px">{employee.node.name}</Text>
                    <Text fontSize="14px">{employee.node.department}</Text>
                    <Text fontSize="14px">{employee.node.mobile}</Text>
                    <Text fontSize="14px">{employee.node.office}</Text>
                    <Text fontSize={{base:"12px", sm:"14px"}}>{employee.node.email}</Text>
                  </VStack>
                </Box>
              ))}

            </SimpleGrid>
          </VStack>
        </Container>
      </Box>
      {/** GIVING BACK */}
      <Box background="linear-gradient(180deg,#e8e8e8,#fff 45%,#fff)"  px={{base:"15px", md: "50px"}}>
        <Container maxW={['lg', '4xl']} mt="20px" mb="40px">
          <VStack>
            <Text fontSize="4xl">
              GIVING BACK
            </Text>
            <Text textAlign="center">
              We believe in supporting the communities we serve. That’s why a portion of all proceeds from every order goes to a local cause. We work with a variety of charitable organizations to make sure every dollar has an impact.
            </Text>
            <HStack>
              <SimpleGrid columns={{ base: 2, sm: 7 }} spacing={{ base: '30px', sm: 0 }} marginY="40px" w="full">
                <StaticImage objectFit='contain' src="../images/about-us/continue.jpeg" alt="Continue" />
                <StaticImage objectFit='contain' src="../images/about-us/road-home.jpg" alt="Continue" />
                <StaticImage objectFit='contain' src="../images/about-us/thx-giving.png" alt="Continue" />
                <StaticImage objectFit='contain' src="../images/about-us/walk-to-cure.jpg" alt="Continue" />
                <StaticImage objectFit='contain' src="../images/about-us/fisher-house.jpg" alt="Continue" />
                <StaticImage objectFit='contain' src="../images/about-us/human.jpeg" alt="Continue" />
                <StaticImage objectFit='contain' src="../images/about-us/family-promise.jpeg" alt="Continue" />
              </SimpleGrid>
            </HStack>
          </VStack>
        </Container>
      </Box>
    </main>
    <Footer />
  </>
}

export default aboutUsPage;

// Retrieve the 'about us' employee list from Contentful, sorted in ascending order by the 'order' attribute on each employee entry
// The entries will be displayed in this order on the About Us page.
export const query = graphql`
query EmployeesQuery {
  allContentfulEmployees(sort: { fields: order, order: ASC}) {
    edges {
      node {
        contentful_id
        email
        mobile
        name
        node_locale
        office
        order
        photo {
          url
        }
      }
    }
  }
}
`

