import { useMutation } from "react-query";
import { axios } from "../../lib/axios";
import { PlanCoverage } from "./bidbox";

export type Product = {
  id: string;
  name: string;
  price: number;
  location?: { slug: string; locationName: string }[];
};

export type CouponCode = {
  code: string;
  isAnnual: boolean;
  products: PlanCoverage[];
};

export type Checkout = {
  first_name: string;
  last_name: string;
  company_name?: string;
  country: string;
  bill_street: string;
  bill_apartment?: string;
  bill_city: string;
  bill_state: string;
  bill_zip: string;
  phone: string;
  email: string;
  warranty_street: string;
  warranty_city: string;
  warranty_state: string;
  warranty_zip: string;
  order_notes?: string;
  card_number: string;
  card_expiration_year: string;
  card_expiration_month: string;
  card_code: string;
  isAnnual: boolean;
  products?: PlanCoverage[];
  agent_name?: string;
  agent_email?: string;
  agent_phone?: string;
  agent_brokerage?: string;
  title_company?: string;
  title_email?: string;
  title_phone?: string;
  title_name?: string;
  lender_company?: string;
  lender_email?: string;
  lender_phone?: string;
  lender_name?: string;
  tc_company?: string;
  tc_email?: string;
  tc_phone?: string;
  tc_name?: string;
};

export type Coupon = {
  code: string;
  type: "Fixed" | "Percent";
  amount: number;
  expirationDate: Date;
  products: Array<"Real Estate" | "Home Owner">;
};

const validateCoupon = (data: CouponCode): Promise<any> => {
  return axios.post("/payment/coupons/validate", data);
};

export const useValidateCoupon = () => {
  return useMutation({
    mutationFn: validateCoupon,
  });
};

const submitCheckout = (data: Checkout): Promise<any> => {
  return axios.post("/payment/credit-card/checkout", data);
};

export const useSubmitCheckout = () => {
  return useMutation({
    mutationFn: submitCheckout,
  });
};
