import React from 'react'
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { SEO } from '../components/seo'
import Footer from "../components/footer"
import NavBar from "../components/header"
import { documentToReactComponents, } from '@contentful/rich-text-react-renderer';
import { navigate } from 'gatsby'
import { Container, VStack, Text, SimpleGrid, Box, Button, HStack } from '@chakra-ui/react'

const faqPage = (props: any) => {
  const faqsContentful = props.data.allContentfulFaQs.edges;
  const faqs = faqsContentful.map((faq: any) => {
    const answer = JSON.parse(faq.node.answer.raw);
    return {
      question: faq.node.question,
      answer
    }
  })

  return <>
    <SEO title="Contact Us" description="Acclaimed Home Warranty" />
    <NavBar />
    <main>
      {/** Banner */}
      <div className="w-full" style={{ overflow: "visible" }}>
        <Container maxW={['lg', '6xl']} pt="32" position="relative">
          <HStack w="full" justifyContent="center" alignItems="flex-end" mb="20px" >
            <Box w="240px" h="200px" display={{ sm: "none", lg: "block" }} background="#beeb9e" borderRadius="100%" position="absolute" bottom="-60px" left="-90px" zIndex="-100">
            </Box>
            <Box w="250px" position="absolute" bottom="-45px" left="30px">
              <StaticImage src="../images/norman-stand.png" alt="AcclaimedHW contact us" />
            </Box>
            <VStack gap="0" lineHeight="0.8" pb="20px" >
              <Box w="75px">
                <StaticImage src="../images/light-bulb.png" alt="" />
              </Box>
              <Text fontFamily="'League Spartan', sans-serif" textAlign="center" fontSize={{ base: '3xl', md: '5xl', lg: '7xl' }} textColor="#000">DID YOU KNOW?</Text>
            </VStack>
            <Box w="200px" h="200px" display={{ sm: "none", lg: "block" }} background="#beeb9e" borderRadius="100%" position="absolute" bottom="40px" right="-15px" zIndex="-100">

            </Box>
          </HStack>
        </Container>
        <Box w="full" h="12" background="#01a1ba" py="2">
          <Text fontSize="xl" textAlign="center" pl="80px" fontFamily="'League Spartan', sans-serif"> Acclaimed Home Warranty answers your questions!</Text>
        </Box>
      </div>
      <Container maxW={['lg', '6xl']} mt="20px" mb="40px">
        <VStack spacing="20px">
          <SimpleGrid columns={{ base: 1, lg: 2 }} spacing="30px" marginY="40px">
            {faqs.map((faq: any, index: any) => (
              <Box boxShadow="0 0 2px #e5f7d8" padding="20px" rounded="3xl" key={index} background="#e5f7d8">
                <VStack>
                  <Text color="#000" fontSize="2xl" textAlign="center">{faq.question}</Text>
                  <div style={{ textAlign: 'justify' }}>
                    {documentToReactComponents(faq.answer)}
                  </div>
                </VStack>
              </Box>
            ))}

          </SimpleGrid>
        </VStack>
      </Container>
      <Box background="linear-gradient(180deg,#e8e8e8,#fff 45%,#fff)" padding="50px">
        <Container maxW={['lg', '6xl']} mt="20px" mb="40px">
          <VStack spacing="15px">
            <Text fontSize="3xl">
              Contact Us Today With Additional Questions
            </Text>
            <Text>
              If you have additional questions about our home warranty options for buyers, sellers, or real estate agents, please reach out to Acclaimed Home Warranty today. We can provide you with all the information you need and tell you about the specifics of each plan we offer. You’ll have the freedom to choose the type of coverage that works best for you, so you don’t have to pay extra for coverage you don’t need. Give us a call today to get the process started!
            </Text>
            <Button colorScheme="green" onClick={() => { navigate('/contact-us') }}>Contact Us</Button>
          </VStack>
        </Container>

      </Box>
    </main>
    <Footer />
  </>
}

export default faqPage;

export const query = graphql`
query FaqQuery {
  allContentfulFaQs {
    edges {
      node {
        answer {
          raw
        }
        question
      }
    }
  }
}
`

