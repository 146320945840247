import * as React from "react";
import Footer from "../components/footer";
import NavBar from "../components/header";
import { SEO } from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import {
  Button,
  Text,
  Flex,
  Container,
  SimpleGrid,
  VStack,
  Box,
  HStack,
} from "@chakra-ui/react";
import { useCreateClaim } from "../services/api/make-claim";


const MakeAClaimPage = (props: any) => {
  const [isOtherService, setOtherService] = React.useState(false);

  const createClaimMutation = useCreateClaim();

  return (
    <>
      <SEO
        title="Make a Claim | Acclaimed Home Warranty"
        description="Acclaimed Home Warranty - Make a Claim"
      />
      <NavBar />
      <main>
      <div className="w-full" style={{ overflow: "visible" }}>
          <Container maxW={["xl", "8xl"]} pt="12" position="relative" minH={{ base:"180px", lg:"400px", xl: "450px"}} fontFamily="Montserrat, sans-serif" >
            <HStack w="full" justifyContent="center" alignItems="baseline">
              <Box 
              display={{base: "none", md:"block"}}
              w={{base: "200px", md:"300px",lg:"350px", xl: "410px"}} h={{base:"200px", md:"300px", lg:"350px", xl:"410px"}} display={{ base: "none", sm: "block" }} background="#7cdfe4" borderRadius="100%" position="absolute" top={{base:"-40px", lg:"-50px", xl: "-80px"}} left="0px" zIndex="-100">
             
              </Box>
              <Box
                display={{base: "none", md:"block"}}
                w={{base:'200px', md:"300px", lg:"300px", xl:'400px'}}
                left="0"
                bottom={{base: '-25px', xl:'-25px'}}
                 style={{
                  position: "absolute",                  
                  
                }}>
                  <StaticImage src="../images/refrigerator.png" alt="AcclaimedHW Refer a Friend" />
                </Box>
              <Flex flexFlow="column">
                <Flex flexFlow="row" alignItems="flex-end">
                <Text fontFamily="'League Spartan', sans-serif" fontWeight="bold" textAlign="center" fontSize={{ base: '3xl', sm:'4xl', md: '5xl', lg: '8xl' }} textColor="#000">Make A </Text>  
                <Box w={{base: "80px", md: "100px", lg:"140px", xl: "180px"}} mx="40px">
                  <StaticImage src="../images/wrench-hammer.png" alt="" />
                </Box>
                </Flex>
              
              <Text fontFamily="'League Spartan', sans-serif" fontWeight="bold" textAlign="center" fontSize={{ base: '3xl', sm:'4xl', md: '5xl', lg: '8xl' }} paddingLeft="100px" textColor="#000">
               Claim</Text>
              </Flex>
              
              
              <Box display={{ base: "none", md: "flex" }} w={{base: "200px", md:"300px", lg:"400px", xl: "470px"}} h={{base:"200px", md:"300px", lg:"350px", xl:"410px"}} background="#7cdfe4" borderRadius="100%" 
                alignItems="flex-end"
                justifyContent="center"
                position="absolute" 
                bottom="-50px"
                right="0"
                zIndex="-100"
              >
               
              </Box>
              <Box
                display={{base: "none", md:"block"}}
                w={{base:'200px', md:"300px", lg:"300px", xl:'400px'}}
                zIndex="100"
                right="15px"
                bottom="-20px"
                 style={{
                  position: "absolute",                  
                }}>
                  <StaticImage src="../images/norman-plumber.png" alt="AcclaimedHW Refer a Friend" />
                </Box>
            </HStack>
          </Container>
          <Box w="full" h={{base: '14', md: "12"}} background="#00c4cc" py="2">
           
          </Box>
        </div>
        <Container maxW={["xl", "7xl"]} mt="20px" mb="40px">
          <Flex direction="column">
            <Text textAlign="center" fontSize="2xl">
              For The Fastest Service, Please Call Us At{" "}
              <a href="tel:888-494-9460" style={{ color: "#6fa553" }}>
                888-494-9460
              </a>{" "}
              During Business Hours.
            </Text>
            <SimpleGrid
              columns={{ base: 1, md: 2 }}
              marginY="40px"
              spacing="30px"
            >
              <VStack
                boxShadow="8px 10px 14px 5px #b9b9b9"
                borderRadius="10px"
                padding="22px 14px"
              >
                <Text fontWeight="bold">What To Expect:</Text>
                <Text textAlign="justify">
                  Once your request has been received by our team members they
                  will be in touch. Please make sure your emails and phone
                  numbers are correct for contact.
                  <br />
                  <br />
                  After your claim has been processed, a contractor will be
                  assigned and will call you to schedule. You also will receive
                  an email with their information. You are welcome to call them
                  to schedule for faster service. The services will be initiated
                  under normal circumstances within 24- 48 hours (minus
                  holidays) of your service request to AHW. In cases of
                  emergency, we will make reasonable efforts to expedite service
                  calls within 24 hours.
                </Text>
                <Text fontWeight="bold" textAlign="left">
                  A service fee is due for each item make on a claim.
                </Text>
              </VStack>
              <VStack
                spacing="20px"
                boxShadow="8px 10px 14px 5px #b9b9b9"
                borderRadius="10px"
                padding="22px 14px"
              >
                <Text fontWeight="bold">What Is Considered An Emergency?</Text>
                <Text textAlign="justify">
                  An emergency situation is defined as a failure of a covered
                  item resulting in 1) a condition that immediately endangers
                  health and safety; 2) a system failure that is causing ongoing
                  damage to the home; 3) plumbing failure that causes interior
                  leaking; and 4) a complete loss of heat or air conditioning in
                  extreme temperatures.
                </Text>
                <Text textAlign="left" fontStyle="italic">
                  *AHW shall not be held liable for any damage or injury caused
                  by any failure or delay in providing repair service. AHW will
                  accept your request to expedite scheduling of a non-emergency
                  service when a service contractor is available, however, you
                  will be responsible for payment of additional fees, including
                  overtime.
                </Text>
              </VStack>
            </SimpleGrid>
          </Flex>
          <Flex>
            <Button marginX="auto" as={'a'} href="https://claim-acclhw.bidboxpro.com/" target="_blank">Login to make a claim</Button>
          </Flex>
        </Container>
      </main>
      <Footer />
    </>
  );
};

export default MakeAClaimPage;
