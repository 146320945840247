import { Container, VStack, Text, Grid, Link, Img, HStack, Box } from "@chakra-ui/react"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Footer from "../components/footer"
import NavBar from "../components/header"
import { SEO } from "../components/seo"
import ArizonaImage from '../images/state-images/Arizona.jpg'
import UtahImage from '../images/state-images/Utah.jpg'
import NevadaImage from '../images/state-images/Nevada.jpg'
import TexasImage from '../images/state-images/Texas.jpg'
import IdahoImage from '../images/state-images/Idaho.jpg'
import PDFIcon from '../images/pdf-icon.png'

const brochureMockData = [
  {
    location: "Arizona",
    link: 'https://assets.ctfassets.net/8baje2n8ckk1/onsuL6uI9LEgi8Nm8h7qt/00fc8fca099ca7ac90a4aec935f28915/AZ_Brochure_2025.pdf',
    image: ArizonaImage
  },
  {
    location: "Utah",
    link: 'https://assets.ctfassets.net/8baje2n8ckk1/3IXTkaXqu6fSgMj5VeeRAu/4ae5588d1e30bc8e82cf47b835ec225f/UT_Brochure_2025.pdf',
    image: UtahImage
  },
  {
    location: "Nevada",
    link: 'https://assets.ctfassets.net/8baje2n8ckk1/64xSOh2juDw7UZckMppIEH/c4ae1618a655497ede36082af1fd6c46/NV_Brochure_2025.pdf',
    image: NevadaImage
  },
  {
    location: "Texas",
    link: 'https://assets.ctfassets.net/8baje2n8ckk1/32Ff2y3DM0MxVnL2Bjic8l/e254bb87238a853e3b1567cf90980862/TX_Brochure_2025.pdf',
    image: TexasImage
  },
  {
    location: "Idaho",
    link: 'https://assets.ctfassets.net/8baje2n8ckk1/2uzDnxhVTMTBEmL5MBx4QS/b2d4a4dde6cf8be480d95aed6b72a58b/ID_Brochure_2025.pdf',
    image: IdahoImage
  },
]

const BrochuresPage = (props: any) => <>
  <SEO title="Brochures | Acclaimed Home Warranty"
    description="Acclaimed Home Warranty - Brochures" />
  <NavBar />
  <main>
    {/** Banner */}
    <div className="w-full" style={{ overflow: "visible" }}>
      <Container maxW={['lg', '7xl']} pt="0" position="relative">
        <HStack w="full" justifyContent="center">
          <Box w="300px" h="300px" display={{ base: "none", md: "block" }} background="#81e1e5" borderRadius="100%" position="absolute" bottom="-35px" left="-40px" zIndex="-100">
          </Box>
          <HStack>
            <Box w={{base:"100px", sm:"180px", lg: "250px"}}>
              <StaticImage src="../images/norman-read.png" alt="AcclaimedHW contact us" />
            </Box>
            <Text textAlign="center" fontWeight="bold" fontSize={{ base: '3xl', sm:'4xl', md: '5xl', lg: '8xl' }} textColor="#beeb9e" fontFamily="'League Spartan', sans-serif">Brochures</Text>
          </HStack>
          <Box w="300px" h="300px" display={{ base: "none", md: "block" }} background="#81e1e5" borderRadius="100%" position="absolute" bottom="40px" right="-50px" zIndex="-100">

          </Box>
        </HStack>
      </Container>
      <Box w="full" h="10" background="#01a1ba" py="2">
      </Box>
    </div>
    <Container maxW={['lg', '6xl']} my="80px">
      <Grid templateColumns={{ base: "repeat(2, 1fr)", sm: "repeat(3, 1fr)", md: "repeat(3, 1fr)", lg: "repeat(5, 1fr)" }} gap={6} mt="3">
        {brochureMockData.map((data) => (
          <VStack key={data.location} pb={{base: "20", lg: "0"}}>
            <Box w="100px" h="100px">
              <Img src={data.image} w="full" />
            </Box>
            <Text textAlign="center" py={{base:"2", lg:"8"}} fontSize='2xl'>
              {data.location}
            </Text>
            <Link target="_blank" href={data.link} display="flex" flexFlow="column" alignItems="center" textAlign="center">
              <Box w="120px">
                <img src={PDFIcon} alt="" />
              </Box>
              Pricing/Options
            </Link>
          </VStack>
        ))}

      </Grid>
    </Container>
  </main>
  <Footer />
</>

export default BrochuresPage
