import { RadioGroup, Select, Stack } from '@chakra-ui/react';
import React, { ReactNode, useState } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type RadioFieldProps = FieldWrapperPassThroughProps & {
  className?: string;
  registration?: Partial<UseFormRegisterReturn>;
  placeholder?: string;
  children?: ReactNode
  defaultValue?: string
};

export const RadioField = (props: RadioFieldProps) => {
  const { label, className, registration, error, placeholder, children, defaultValue, ...rest } = props;
  const [value, setValue] = useState(defaultValue)
  return (
    <FieldWrapper label={label} error={error} {...rest}>
      <input type="hidden" {...registration} value={value} />
      <RadioGroup
        className={className}
        onChange={setValue}
        value={value}
      >
        <Stack>
          {
            children
          }
        </Stack>
      </RadioGroup>
    </FieldWrapper>
  );
};
