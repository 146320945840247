import * as React from "react"
import Footer from "../components/footer"
import NavBar from "../components/header"
import { SEO } from '../components/seo'
import { StaticImage } from "gatsby-plugin-image"
import * as z from 'zod';
import { Button, Text, Flex, Container, Box, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, Radio, Checkbox, CheckboxGroup, Stack, ListItem, OrderedList } from "@chakra-ui/react"


const HomeOwners = (props: any) => {

  return (
    <>
      <SEO title="Home Owner Warranty Plans" description="Acclaimed Home Warranty - Real Estate Client Protection" />
      <NavBar />
      <main>
        <StaticImage src="../images/make-claim-banner.png" layout="fullWidth" alt="AcclaimedHW contact us" />
        <Container maxW={['lg', '4xl']} mt="20px" mb="40px">
          <Flex direction="column">
            <Text fontSize='5xl' textAlign="center">Only Trust The Best Home Warranty Company</Text>
            <Text textAlign="center" fontSize="xl">
              <br />
              <br />
              As the owner of a home, you know the worry that can come with it. 
              While it’s nice to have a place to call all your own, there’s always the concern that something will break or need repair unexpectedly, leaving you to foot the bill. 
              However, a home warranty plan can eliminate this worry, giving you the peace of mind of knowing your appliances and systems are covered. 
              As one of the best home warranty companies in 
              <a href="homeowner-plans/arizona/">Arizona</a>, 
              <a href="homeowner-plans/utah/">Utah</a>, 
              <a href="homeowner-plans/nevada/">Nevada</a>, 
              <a href="homeowner-plans/texas/">Texas</a>, and 
              <a href="homeowner-plans/idaho/">Idaho</a>, 
              Acclaimed Home Warranty can help you ensure you have the protection your home needs. 
              Please reach out to us today to learn more about our coverage options.
            </Text>
          </Flex>
        </Container>


      </main>
      <Footer />
    </>
  )
}

export default HomeOwners
