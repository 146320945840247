import * as React from "react"

// This is simply a redirect to the homepage, because Google still is indexing the old /order-now/ URL and we don't want to lose those clicks

// markup
const OrderNowPage = () => {
  return (
    <meta http-equiv="Refresh" content="0; url='/'" />
  )
}

export default OrderNowPage
