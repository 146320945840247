import * as React from "react"
import Footer from "../components/footer"
import NavBar from "../components/header"
import { SEO } from '../components/seo'
import { StaticImage } from "gatsby-plugin-image"
import * as z from 'zod';
import { Button, Text, Flex, Container, Box, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, Radio, Checkbox, CheckboxGroup, Stack, ListItem, OrderedList } from "@chakra-ui/react"


const RealEstate = (props: any) => {

  return (
    <>
      <SEO title="Real Estate Client Protection" description="Acclaimed Home Warranty - Real Estate Client Protection" />
      <NavBar />
      <main>
        <StaticImage src="../images/make-claim-banner.png" layout="fullWidth" alt="AcclaimedHW contact us" />
        <Container maxW={['lg', '4xl']} mt="20px" mb="40px">
          <Flex direction="column">
            <Text fontSize='5xl' textAlign="center">Home Warranty Plans For Real Estate</Text>
            <Text fontSize='3xl' textAlign="center">Acclaimed Home Warranty helps you protect your investments</Text>
            <Text>
              <br />
              <br />
              <p>Whether you are a real estate agent, title officer, mortgage lender or purchasing a home, <a href="/real-estate-order/"> we have great plans and coverages to choose from.</a></p>
              <p><a href="/real-estate-order/"> Click here to see your options and to easily order.</a> Invoices will be provided to send a check to at the end of the order.</p>
              <p>If you need to pay another way, please order and email hello@acclaimedhw.com or call us for payment options.</p>
              <p>Thank you for your business.</p>
            </Text>
          </Flex>
        </Container>


      </main>
      <Footer />
    </>
  )
}

export default RealEstate
