import React from "react";
import { graphql } from "gatsby";
import Header from "../components/header";
import Footer from "../components/footer";
import { SEO } from "../components/seo";
import PropertySizeSelector from "../components/sections/property-size-select";
import { PlanGroup } from "../services/api/bidbox";

export interface Props {
  pageContext: PlanGroup;
}

const LocationTemplate: React.FC<Props> = ({
  pageContext: data,
}): JSX.Element => {

  return (
    <>
      <SEO title="Location">
        <link
          href="https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap"
          rel="stylesheet"
        />
      </SEO>
      <Header />

      {data.options && <PropertySizeSelector plans={data.options} state={data.name} />}

      <Footer />
    </>
  );
};

export default LocationTemplate;

type PlanData = {
  features: [string];
  displayName: string;
  startingAt: number;
  order: number;
  priceAnnual: number;
  priceMonthly: number;
  unitSize: string;
};

export type LocationData = {
  locationName: string;
  slug: string;
  serviceFee: number | null;
  displayName: string | null;
  startingAt: number | null;
  resources?: [
    {
      name?: string;
      videoUrl?: string;
      type?: string;
      slug?: string;
    }
  ];
  features?: [
    {
      items?: [string] | null;
      title?: string;
    }
  ];
  plans: {
    "Single Family"?: [PlanData];
    Duplex?: [PlanData];
    Triplex?: [PlanData];
    Fourplex?: [PlanData];
  };
};

export type CoverageUpgradeDataNode = {
  id: string;
  location?: { locationName: string; slug: string }[];
  modalContent?: { raw: string };
  modalLinkText?: string;
  name: string;
  price: number;
};

export type CoverageUpgradeData = {
  allContentfulCoverageUpgrade: {
    edges: {
      node: CoverageUpgradeDataNode;
    }[];
  };
};
