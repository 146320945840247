import * as React from 'react'
import { QueryClientProvider } from 'react-query';
import { QueryClient, DefaultOptions } from 'react-query';
import { Toaster } from 'react-hot-toast';
const queryConfig: DefaultOptions = {
  queries: {
    useErrorBoundary: true,
    refetchOnWindowFocus: false,
    retry: false,
  },
};

export const queryClient = new QueryClient({ defaultOptions: queryConfig });

const layout = ({children}: any) => {
  return (<QueryClientProvider client={queryClient}>
    <Toaster/ >
    {children}
  </QueryClientProvider>)
}

export default layout;