/** @format */

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  FormLabel,
  Heading,
  HStack,
  Icon,
  ListItem,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  SimpleGrid,
  Text,
  UnorderedList,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import NavBar from "../components/header";
import { SEO } from "../components/seo";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { navigate } from "gatsby";
import { Colors } from "../config";
import { RealEstatePlanOptions } from "../components/sections/property-size-select";
import Footer from "../components/footer";
import {
  PlanCoverage,
  PlanGroup,
  PropType,
  WarrantyPlan,
} from "../services/api/bidbox";

const StandardPlanFeatures = (props: { options: WarrantyPlan[] }) => {
  const { options } = props;
  if (!options) return <></>;

  return (
    <HStack alignItems="flex-start" pt={4}>
      {options.map((plan, index: number) => {
        return (
          <Box flex={1} key={index} alignItems="flex-start">
            <Text fontSize="lg" fontWeight="bold" decoration="underline" mb={2}>
              {plan.name}
            </Text>
            {index > 0 && (
              <Text fontSize="md" mb={3} fontWeight={500}>
                "Everything that is included in {options[index - 1].name}"
                Plus... <br />
                (List items unique to {plan.name})
              </Text>
            )}

            <VStack alignItems="flex-start" spacing={1}>
              {plan.features.map((text, key: number) => {
                if (index > 0 && options[index - 1].features.includes(text))
                  return null;
                return (
                  <Text key={`item-${key}`} textTransform="capitalize">
                    {text}
                  </Text>
                );
              })}
            </VStack>
          </Box>
        );
      })}
    </HStack>
  );
};

interface Props {
  pageContext: PlanGroup;
  location: {
    state: {
      amount: number;
      [key: string]: any;
    };
  };
}

const PlansAndPricing: React.FC<Props> = ({
  pageContext,
  ...props
}): JSX.Element => {
  const CoverageUpgrades = (props: {
    policy: WarrantyPlan;
    onCancel: () => void;
  }) => {
    const { policy } = props;
    const [isAnnual, setAnnual] = React.useState(true);
    const [isMultiQuantity, setMultiQuantity] = React.useState(!(policy.new_construction || policy.plan_type === 'Listing'));
    const [multipleYears, setMultipleYears] = React.useState(1);
    const [carts, setCarts] = React.useState<Array<PlanCoverage>>([
      // Construct a "fake" option to represent the Policy, but give it a unique id so it doesn't clobber existing option ids
      {
        id: "policy-" + policy.id,
        system:
          `${policy.prop_types[0]} ${policy.name}` +
          (policy.affiliate_sp ? " - Affiliate Pricing" : ""),
        cost: policy.affiliate_sp ? policy.affiliate_sp : policy.retail_sp,
        cost_monthly: policy.affiliate_sp_monthly
          ? policy.affiliate_sp_monthly
          : policy.retail_sp_monthly,
        qty: 1,
        enabled: true,
        optional: false,
      },
    ]);
    // Add surcharge if user said their house exceeds 6000 square feet.
    // NOTE!  "6000+ Sqft" is the exact name of the coverage option we are looking for - if the name is changed, this code will no longer work.
    //        The option in the BBP back-end should be enabled and set to optional=false. That will prevent it appearing in the list as a user-selectable item.
    const option_idx_6k = policy.coverage.findIndex(
      (item: PlanCoverage) =>
        item.system === "6000+ Sqft" && item.enabled === true
    );
    if (
      option_idx_6k >= 0 &&
      under6K == false &&
      /* don't push if it's already in the array */ false ==
        carts.some(
          (item: PlanCoverage) => item.id === policy.coverage[option_idx_6k].id
        )
    ) {
      const node = policy.coverage[option_idx_6k];
      node.qty = Number(1);
      carts.push(node);
    }

    const onCancel = () => {
      props.onCancel();
    };
    const totalPrice = React.useMemo(() => {
      const price = carts
        .map((item, index) => item.cost * (item.qty ?? 0))
        .reduce((prev, current) => prev + current, 0)
        .toFixed(2);

      return Number(price);
    }, [carts]);

    const onSwitchItem = (node: PlanCoverage, v: number) => {
      const newCarts: Array<PlanCoverage> = Object.assign([], carts);
      if (v > 0) {
        const existCart = newCarts.find(
          (item: PlanCoverage) => item.id === node.id
        );
        if (existCart) {
          existCart.qty = Number(v);
        } else {
          node.qty = Number(v);
          newCarts.push({ ...node });
        }
      } else {
        const index = newCarts.findIndex(
          (item: PlanCoverage) => item.id === node.id
        );
        if (index === -1) return;
        newCarts.splice(index, 1);
      }
      setCarts(newCarts);
    };

    const onCheckOut = () => {
      navigate("/checkout", {
        state: {
          isAnnual,
          multipleYears: isMultiQuantity ? multipleYears : 1,
          totalPrice: totalPrice * multipleYears,
          carts,
          isHomeownerOrder: false,
          policy: policy,
          under6K: under6K,
          agentType: agentType,
        },
      });
    };

    return (
      <>
        <Box w="full" textAlign="left" my={5}>
          <Heading
            as="h1"
            fontSize="2xl"
            fontFamily="TrajanProRegular"
            color="green"
          >
            Coverage Upgrades
          </Heading>
          <Heading as="h1" fontSize="md" fontWeight={300}>
            SELECT ADDITIONAL ITEMS TO CUSTOMIZE YOUR PLAN
          </Heading>
        </Box>

        <SimpleGrid
          columns={{ base: 1, md: 3, sm: 2 }}
          spacing={5}
          w="100%"
          my={7}
        >
          {policy.coverage
            .filter(
              (coverage) =>
                coverage.optional &&
                coverage.enabled &&
                coverage.cost /* Hide disabled and zero-cost options */ &&
                coverage.id !=
                  policy.coverage[option_idx_6k]
                    .id /* Hide the 6000+ sqft option from view - we lock it in based on earlier user responses to questions */
            )
            .map((coverage, index: number) => {
              return (
                <HStack key={index}>
                  <NumberInput
                    w="16"
                    defaultValue={0}
                    step={1}
                    min={0}
                    max={9}
                    onChange={(v: any) => onSwitchItem(coverage, v)}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <FormLabel
                    htmlFor={`switch-${index}`}
                    fontSize="sm"
                    color="black"
                  >
                    ${coverage.cost}
                  </FormLabel>
                  <FormLabel
                    htmlFor={`switch-${index}`}
                    fontSize="sm"
                    fontWeight="300"
                    color="gray.700"
                  >
                    | {coverage.system}
                  </FormLabel>
                </HStack>
              );
            })}
        </SimpleGrid>

        <HStack w="full" alignItems="end" mt={6}>
          <Box textAlign="left" w="full" alignSelf="baseline">
            <Heading as="h1" fontSize="xl" fontWeight="500" mb={3}>
              Cart
            </Heading>
            <Box w="full" alignItems="left">
              {carts.map((item, index) => (
                <Text fontSize="sm" key={`${index} ${item.system}`}>
                  {Number(item.qty) ?? 1}x {item.system}{" "}
                  <b>${(item.cost * Number(item.qty) ?? 1).toFixed(2)}</b>
                </Text>
              ))}
            </Box>
          </Box>

          <VStack alignItems="flex-end" textAlign="right" w="full">
            {isMultiQuantity ? (
                <HStack>
                  <NumberInput
                      w="16"
                      defaultValue={1}
                      min={1}
                      max={5}
                      step={1}
                      onChange={(v: any) => {
                        setMultipleYears(v);
                      }}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <Heading as="h1" fontSize="xl" fontWeight="500" mb={3}>
                    {isAnnual ? "Yrs" : "Mos"}
                  </Heading>
                </HStack>
            ) : (
                <HStack />
            )}
            <Heading as="h1" fontSize="xl" fontWeight="500" mb={3}>
              Total ${(totalPrice * multipleYears).toFixed(2)}
              {isAnnual ? "" : ", billed monthly"}
            </Heading>

            <HStack display="inline">
              <Button colorScheme="green" onClick={onCheckOut}>
                Check out
              </Button>
              <Button colorScheme="teal" onClick={onCancel}>
                Cancel
              </Button>
            </HStack>
          </VStack>
        </HStack>
      </>
    );
  };

  useEffect(() => {
    if (!props.location.state) navigate(-1);
  }, []);

  const [selectedPolicy, setSelectedPolicy] = useState<WarrantyPlan | null>(
    null
  );

  const onBack = () => {
    navigate(-1);
  };

  let {
    amount = 0,
    homeType = "single",
    newHome,
    under6K,
    agentType,
  } = props.location.state || {};
  const planTypes: Record<string, PropType> = {
    single: "SingleFamily",
    duplex: "Duplex",
    triplex: "Triplex",
    fourplex: "Fourplex",
    mobilehome: "MobileHome",
  };
  if (agentType == "listing_agent") {
    homeType = "single";
  }

  const planType = planTypes[homeType];
  const homeTypePlans = pageContext
    .options![planType].sort((a, b) => {
      if (a.name == "New Construction") return 1;
      if (b.name == "New Construction") return -1;
      return a.retail_sp - b.retail_sp;
    })
    .slice();

  if (agentType == "listing_agent") {
    // remove all other options than listing
    for (let i = 0; i < homeTypePlans.length; i++) {
      if (homeTypePlans[i].plan_type !== "Listing") {
        homeTypePlans.splice(i, 1);
        i--;
      }
    }
  } else {
    //  remove listing option
    for (let i = 0; i < homeTypePlans.length; i++) {
      if (homeTypePlans[i].plan_type === "Listing") {
        homeTypePlans.splice(i, 1);
        i--;
      }
    }
  }

  const onChoosePlan = (plan: WarrantyPlan) => {
    setSelectedPolicy(plan);
  };
  return (
    <>
      <SEO title="Real Estate Order" description="Acclaimed Home Warranty" />
      <NavBar />

      <main>
        {/** Banner */}
        <div className="w-full" style={{ overflow: "visible" }}>
          <Container maxW={["lg", "6xl"]} pt="12" position="relative">
            <HStack
              w="full"
              justifyContent="center"
              alignItems="flex-end"
              pl="150px"
            >
              <Box
                w={{ base: "200px", lg: "400px" }}
                position="absolute"
                left="0"
                bottom="-20px"
              >
                <StaticImage
                  src="../images/norman-thank-you.png"
                  alt="AcclaimedHW contact us"
                />
              </Box>
              <VStack
                gap="0"
                lineHeight="0.8"
                pb="20px"
                fontFamily="'League Spartan', sans-serif"
              >
                <Text
                  fontFamily="'League Spartan', sans-serif"
                  textAlign="center"
                  fontSize={{ base: "3xl", md: "5xl", lg: "7xl" }}
                  textColor="#000"
                >
                  REAL ESTATE
                </Text>
                <Text
                  fontFamily="'League Spartan', sans-serif"
                  textAlign="center"
                  fontSize={{ base: "3xl", md: "5xl", lg: "7xl" }}
                  textColor="#000"
                  mb="20px"
                >
                  ORDERS
                </Text>
              </VStack>
            </HStack>
          </Container>
          <Box w="full" h="12" background="#01a1ba" py="2"></Box>
        </div>
        <Container maxW={["lg", "6xl"]} mt="20px" mb="40px">
          <Text fontSize="3xl" mb={5}>
            PLANS & PRICING
          </Text>

          <Button
            bg={Colors.green}
            color={useColorModeValue("white", "gray.800")}
            _hover={{
              bg: "green.600",
            }}
            mb={10}
            onClick={onBack}
          >
            <Icon as={ChevronLeftIcon} w={4} h={4} />
            GO BACK
          </Button>

          <UnorderedList ml={8} mb={10}>
            <ListItem>
              Scan each of our <b>Home Warranty</b> plans side-by-side to
              compare features.
            </ListItem>
            <ListItem>
              Select the Plan that’s best for your needs by clicking the{" "}
              <b>Buy Now</b> button in the column of your choice.
            </ListItem>
            <ListItem>
              Review <b>Coverage Upgrades</b> below. Customize your chosen Plan
              and get a live total by changing option quantities.
            </ListItem>
            <ListItem>
              Click the <b>Check out</b> button at the bottom of the page. After
              placing your order, you may download the invoice or receive an
              invoice via email.
            </ListItem>
          </UnorderedList>

          {!selectedPolicy && (
            <RealEstatePlanOptions
              options={homeTypePlans}
              onChoose={onChoosePlan}
            />
          )}

          {selectedPolicy && (
            <CoverageUpgrades
              policy={selectedPolicy}
              onCancel={() => {
                setSelectedPolicy(null);
              }}
            />
          )}
        </Container>
      </main>

      <Footer />
    </>
  );
};

export default PlansAndPricing;

// export const query = graphql`
//   query RealestatePlansQuery {
//     allContentfulCoverageUpgrade {
//       edges {
//         node {
//           id
//           name
//           price
//           modalLinkText
//           modalContent {
//             raw
//           }
//           location {
//             slug
//             locationName
//           }
//         }
//       }
//     }
//   }
// `;
