import {useEffect} from "react";


export class AuthorizeNet {

    LIVE = 'https://js.authorize.net/v3/AcceptUI.js';
    DEV = 'https://jstest.authorize.net/v3/AcceptUI.js';
    url = '';
    isLoaded = false;

    cb: (any);

    constructor(isProd: boolean) {
        if (isProd) {
            this.url = this.LIVE;
        } else {
            this.url = this.DEV;
        }
    }

    loadScript() {
        if (window.authnetloaded) {
            return;
        }
        window.authnetloaded = true;
        // useEffect(() => {
        this.isLoaded = true;
        // Create a script element
        const script = document.createElement('script');
        script.src = this.url; // Use the correct URL for AcceptUI.js
        script.async = true;

        // Script event handlers
        script.onload = () => console.log('Loaded AcceptUI.js SUCCESS', this.url);
        script.onerror = () => console.error('Failed to load AcceptUI.js', this.url);

        // Append the script to the document body
        document.body.appendChild(script);

        window.authnetresp = (response: any) => {
            this.cb(response);
        }

        // Cleanup on component unmount
        return () => {
            document.body.removeChild(script);
        };
        // }, []);


    }
}