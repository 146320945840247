import * as React from "react";
import Footer from "../components/footer";
import NavBar from "../components/header";
import { SEO } from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Container,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { graphql } from "gatsby";
import * as z from "zod";

// Here we have used react-icons package for the icons
import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";
// And react-slick as our Carousel Lib
import Slider from "react-slick";
import { Form } from "../components/forms/Form";
import { InputField } from "../components/forms/InputField";
import { useState } from "react";
import toast from "react-hot-toast";
import {
  useNetlifyForm,
  NetlifyFormProvider,
  Honeypot,
} from "react-netlify-forms";

// Settings for the slider
const settings = {
  dots: true,
  arrows: false,
  fade: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const phoneRegexp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
const hourlyRateRegexp =
  /^[+-]?[0-9]{1,3}(?:[0-9]*(?:[.,][0-9]{2})?|(?:,[0-9]{3})*(?:\.[0-9]{2})?|(?:\.[0-9]{3})*(?:,[0-9]{2})?)$/g;
const schema = z.object({
  company_name: z.string().min(1),
  contract_name: z.string().min(1),
  contract_number: z
    .string()
    .regex(phoneRegexp, { message: "Not valid phone number" }),
  contract_email: z.string().email(),
  trade_services: z.array(z.string()).or(z.string()),
  service_areas: z.array(z.string()).or(z.string()),
  hourly_rate: z
    .string()
    .regex(hourlyRateRegexp, { message: "Not valid hourly rate" }),
  trade_services_other: z.string().optional(),
});

type CreateContractDTO = {
  company_name: string;
  contract_name: string;
  contract_number: string;
  contract_email: string;
  trade_services: string | Array<string>;
  service_areas: string | Array<string>;
  hourly_rate: string;
  trade_services_other: string;
};

const Contractors = (props: any) => {
  const testimonials = props.data.allContentfulContractorTestimonials?.nodes;

  const [isOtherService, setOtherService] = useState(false);
  const { slides } = props;
  const [slider, setSlider] = React.useState<Slider | null>(null);

  const netlify = useNetlifyForm({
    name: "contractor",
    honeypotName: "bot-field",
    onSuccess: () => {
      toast.success("Successfully submitted");
    },
  });

  const onSubmit = (data: CreateContractDTO) => {
    netlify.handleSubmit(null, {
      ...data,
      subject: "Contractor request from Acclaimed Home warranty",
    });
  };

  // These are the breakpoints which changes the position of the
  // buttons as the screen size changes
  const top = useBreakpointValue({ base: "90%", md: "50%" });
  const side = useBreakpointValue({ base: "30%", md: "40px" });

  // This list contains all the data for carousels
  // This can be static or loaded from a server
  let cards: any[] = [];

  testimonials.map((slide: any) => {
    cards.push({
      company: slide.company,
      name: slide.name,
      image: slide.image.file.url,
      quote: slide.quote.quote,
    });
  });

  return (
    <>
      <SEO title="Contractors" description="Acclaimed Home Warranty" />
      <NavBar />
      <main>
        <div className="w-full" style={{ overflow: "visible" }}>
          <Container
            maxW={["lg", "8xl"]}
            pt={{ base: 16, md: "12" }}
            position="relative"
            h="300px"
          >
            <Box textAlign="center">
              <Text
                fontFamily="montserrat, sans-serif"
                fontWeight="bold"
                fontSize={{
                  base: "3xl",
                  sm: "3xl",
                  md: "3xl",
                  lg: "4xl",
                  xl: "7xl",
                }}
                mt="8"
              >
                Join our team of
                <br />
                Experienced Contractors
              </Text>

              <Box
                w="250px"
                h="230px"
                display={{ base: "none", md: "block" }}
                background="#81e1e5"
                borderRadius="100%"
                position="absolute"
                bottom="-53px"
                left="80px"
                zIndex="-100"
              >
                <span
                  style={{
                    position: "absolute",
                    right: "-21px",
                  }}
                >
                  <StaticImage
                    src="../images/wrench.png"
                    alt="AcclaimedHW contact us"
                  />
                </span>
              </Box>
              <Box
                w="300px"
                h="270px"
                display={{ base: "none", md: "block" }}
                background="#81e1e5"
                borderRadius="100%"
                position="absolute"
                bottom="-53px"
                right="22px"
                zIndex="-100"
              >
                <span
                  style={{
                    position: "absolute",
                    left: "-53px",
                    top: "28px",
                  }}
                >
                  <StaticImage
                    src="../images/hammer.png"
                    alt="AcclaimedHW contact us"
                  />
                </span>
              </Box>
            </Box>
            <Box
              w="250px"
              pos="absolute"
              display={{ base: "none", md: "block" }}
              right="40px"
              bottom="-50px"
              zIndex="banner"
            >
              <StaticImage
                src="../images/norman-tool-box.png"
                alt="AcclaimedHW contact us"
                layout="fullWidth"
              />
            </Box>
          </Container>
          <Box w="full" h="14" background="#beeb9e" py="2">
            <Text
              fontSize={{ base: "md", sm: "lg", lg: "2xl" }}
              textAlign="center"
            >
              Become a part of the Acclaimed Home Warranty Family!
            </Text>
          </Box>
        </div>

        <Container maxW={["lg", "7xl"]} mt="20px" mb="40px">
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="center"
            py="12"
          >
            <Text textAlign="center">
              Being part of the Acclaimed Home Warranty team means making our
              clients feel secure in every aspect of their home. We only work
              with the best contractors, so if you never settle for anything
              less than exceptional service, we want to hear from you.
            </Text>
          </Flex>
          <Flex
            minH="700px"
            alignItems={{ base: "flex-start", xl: "flex-start" }}
            direction={{ base: "column", xl: "row" }}
            background="#fff"
            position="relative"
            zIndex="100"
          >
            <Flex
              direction={{ base: "column" }}
              width={{ base: "100%", xl: "60%" }}
              alignItems={{ base: "center", xl: "flex-start" }}
            >
              <Box background="#beeb9e" px="12" rounded="full" my="4">
                <Text
                  flex="1"
                  fontSize={{ base: "xl", sm: "2xl", md: "4xl", xl: "5xl" }}
                >
                  WHY JOIN OUR TEAM
                </Text>
              </Box>
              <VStack
                gap="5"
                alignItems="flex-start"
                flex="1"
                direction="column"
                px="12"
              >
                <Flex alignItems="center">
                  <StaticImage
                    src="../images/blue-leads.png"
                    width={60}
                    alt="Acclaimed HW free leads"
                  />
                  <Text
                    marginLeft="20px"
                    fontSize={{ base: "18px", md: "23px", xl: "32px" }}
                  >
                    FREE LEADS
                  </Text>
                </Flex>
                <Flex alignItems="center">
                  <StaticImage
                    src="../images/blue-advertising.png"
                    width={60}
                    alt="Acclaimed HW free leads"
                  />
                  <Text
                    marginLeft="20px"
                    fontSize={{ base: "18px", md: "23px", xl: "32px" }}
                  >
                    FREE ADVERTISING
                  </Text>
                </Flex>
                <Flex alignItems="center">
                  <StaticImage
                    src="../images/blue-bussiness.png"
                    width={60}
                    alt="Acclaimed HW free leads"
                  />
                  <Text
                    marginLeft="20px"
                    fontSize={{ base: "18px", md: "23px", xl: "32px" }}
                  >
                    STEADY BUSINESS
                  </Text>
                </Flex>
                <Flex alignItems="center">
                  <StaticImage
                    src="../images/blue-feedback.png"
                    width={60}
                    alt="Acclaimed HW free leads"
                  />
                  <Text
                    marginLeft="20px"
                    fontSize={{ base: "18px", md: "23px", xl: "32px" }}
                  >
                    CUSTOMER FEEDBACK
                  </Text>
                </Flex>
              </VStack>
            </Flex>
            <Box
              background="#00c4cc"
              position={{ base: "relative" }}
              right={{ base: "", xl: "0px" }}
              padding={{ base: "10px", md: "25px" }}
              width={{ base: "90%", xl: "500px" }}
            >
              <NetlifyFormProvider {...netlify}>
                <Form<CreateContractDTO, typeof schema>
                  onSubmit={onSubmit}
                  schema={schema}
                  isNetlify={true}
                  formName="contractor"
                  dataNetlifyHoneypot="bot-field"
                  subject="Contractor request from Acclaimed Home warranty"
                >
                  {({ register, formState }) => (
                    <>
                      <Honeypot />
                      <VStack spacing="10px" background="#fff" padding="15px">
                        <Text fontSize="20px" color="#3a3a3a">
                          How do I Join?
                        </Text>
                        <Text
                          fontSize="14px"
                          color="#3a3a3a"
                          marginBottom="25px"
                          textAlign="center"
                        >
                          We want to make sure you’re a great fit for our team.
                          Fill out this application and we’ll reach out to you
                          to learn more about your organization.
                        </Text>
                        <InputField
                          isRequired
                          error={formState.errors["company_name"]}
                          registration={register("company_name")}
                          placeholder="Company Name *"
                          className="bg-lightgray"
                        />
                        <InputField
                          isRequired
                          error={formState.errors["contract_name"]}
                          registration={register("contract_name")}
                          placeholder="Contact Name *"
                          className="bg-lightgray"
                        />
                        <InputField
                          isRequired
                          error={formState.errors["contract_number"]}
                          registration={register("contract_number")}
                          placeholder="Contact Number *"
                          className="bg-lightgray"
                        />
                        <InputField
                          isRequired
                          error={formState.errors["contract_email"]}
                          registration={register("contract_email")}
                          placeholder="Contact Email *"
                          className="bg-lightgray"
                        />
                        <Accordion allowMultiple width="100%">
                          <AccordionItem>
                            <h2>
                              <AccordionButton>
                                <AccordionIcon />
                                <Box flex="1" textAlign="left">
                                  Trade Services
                                </Box>
                              </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                              <CheckboxGroup colorScheme="cyan">
                                <Stack spacing={[1, 5]} direction={["column"]}>
                                  <Checkbox
                                    {...register("trade_services")}
                                    value="Appliance"
                                  >
                                    Appliance
                                  </Checkbox>
                                  <Checkbox
                                    {...register("trade_services")}
                                    value="HVAC"
                                  >
                                    HVAC
                                  </Checkbox>
                                  <Checkbox
                                    {...register("trade_services")}
                                    value="Plumbing"
                                  >
                                    Plumbing
                                  </Checkbox>
                                  <Checkbox
                                    {...register("trade_services")}
                                    value="Septic"
                                  >
                                    Septic
                                  </Checkbox>
                                  <Checkbox
                                    {...register("trade_services")}
                                    value="Pool/Spa"
                                  >
                                    Pool/Spa
                                  </Checkbox>
                                  <Checkbox
                                    {...register("trade_services")}
                                    value="Roof"
                                  >
                                    Roof
                                  </Checkbox>
                                  <Checkbox
                                    {...register("trade_services")}
                                    value="Electrical"
                                  >
                                    Electrical
                                  </Checkbox>
                                  <Checkbox
                                    {...register("trade_services")}
                                    value="Garage Systems"
                                  >
                                    Garage Systems
                                  </Checkbox>
                                  <Checkbox
                                    {...register("trade_services")}
                                    value="Water Softener"
                                  >
                                    Water Softener
                                  </Checkbox>
                                  <Checkbox
                                    checked={isOtherService}
                                    onChange={(e: any) =>
                                      setOtherService(!isOtherService)
                                    }
                                  >
                                    Other
                                  </Checkbox>

                                  {isOtherService && (
                                    <InputField
                                      isRequired
                                      registration={register(
                                        "trade_services_other"
                                      )}
                                      placeholder=""
                                    />
                                  )}
                                </Stack>
                              </CheckboxGroup>
                            </AccordionPanel>
                          </AccordionItem>

                          <AccordionItem>
                            <h2>
                              <AccordionButton>
                                <AccordionIcon />
                                <Box flex="1" textAlign="left">
                                  Services Areas
                                </Box>
                              </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                              <CheckboxGroup
                                colorScheme="cyan"
                                defaultValue={["Utah"]}
                              >
                                <Stack spacing={[1, 5]} direction={["column"]}>
                                  <Checkbox
                                    {...register("service_areas")}
                                    value="Utah"
                                  >
                                    Utah
                                  </Checkbox>
                                  <Checkbox
                                    {...register("service_areas")}
                                    value="Nevada"
                                  >
                                    Nevada
                                  </Checkbox>
                                  <Checkbox
                                    {...register("service_areas")}
                                    value="Texas"
                                  >
                                    Texas
                                  </Checkbox>
                                  <Checkbox
                                    {...register("service_areas")}
                                    value="Arizona"
                                  >
                                    Arizona
                                  </Checkbox>
                                  <Checkbox
                                    {...register("service_areas")}
                                    value="Idaho"
                                  >
                                    Idaho
                                  </Checkbox>
                                </Stack>
                              </CheckboxGroup>
                            </AccordionPanel>
                          </AccordionItem>
                        </Accordion>
                        <InputField
                          type="text"
                          isRequired
                          error={formState.errors["hourly_rate"]}
                          registration={register("hourly_rate")}
                          placeholder="Hourly Rate *"
                          className="bg-lightgray"
                        />
                        <Button
                          type="submit"
                          colorScheme="lightgreen"
                          variant="solid"
                          w={{ base: "180px", md: "200px" }}
                          alignSelf="center"
                        >
                          Submit Application
                        </Button>
                      </VStack>
                    </>
                  )}
                </Form>
              </NetlifyFormProvider>
            </Box>
          </Flex>
        </Container>
      </main>
      <Footer />
    </>
  );
};

export default Contractors;

export const query = graphql`
  query ContractorQuotesQuery {
    allContentfulContractorTestimonials {
      nodes {
        name
        company
        image {
          file {
            url
          }
        }
        quote {
          quote
        }
      }
    }
  }
`;
