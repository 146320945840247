import {
  Container,
  Text,
  HStack,
  Image,
  Box,
  VStack,
  SimpleGrid,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import NavBar from "../components/header";
import { SEO } from "../components/seo";
import { graphql, Link } from "gatsby";
import Footer from "../components/footer";

export type AllBlogType = {
  allContentfulBlog: {
    edges: [
      node: {
        articleBody: {
          raw: string;
        };
        id: string;
        postPhoto: {
          url: string;
        };
        published: string;
        slug: string;
        title: string;
      }
    ];
  };
};

export default function BlogsPage({ data }: { data: AllBlogType }) {
  const blogs = data.allContentfulBlog.edges;

  return (
    <>
      <SEO
        title="Blogs | Acclaimed Home Warranty"
        description="Acclaimed Home Warranty - Blogs"
      />
      <NavBar />
      <main style={{ marginBottom: "15px" }}>
        {/** Banner */}
        <div className="w-full" style={{ overflow: "visible" }}>
          <Container maxW={["lg", "7xl"]} pt="0" position="relative">
            <HStack w="full" justifyContent="center">
              <Box w={{ base: "100px", sm: "180px", lg: "250px" }}>
                <StaticImage
                  src="../images/norman-desk-laptop.png"
                  alt="AcclaimedHW contact us"
                />
              </Box>
              <Text
                textAlign="center"
                fontWeight="bold"
                fontSize={{ base: "3xl", sm: "4xl", md: "5xl", lg: "8xl" }}
                textColor="black"
                fontFamily="'League Spartan', sans-serif"
              >
                BLOG
              </Text>
            </HStack>
          </Container>
          <Box w="full" h="10" background="#01a1ba" py="2"></Box>
        </div>

        <Container maxW={["lg", "6xl"]} my="90px">
          <Grid
            alignItems="end"
            templateColumns={{
              base: "repeat(2, 1fr)",
              sm: "repeat(3, 1fr)",
              md: "repeat(3, 1fr)",
              lg: "repeat(5, 1fr)",
            }}
            gap={6}
            mt="3"
          >
            {blogs?.map((value: any) => (
              <Link to={"/blogs/" + value.node.slug} key={value.node.id}>
                <GridItem
                  key={value.node.id}
                  pb={{ base: "20", lg: "0" }}
                  h="60%"
                  display="grid" // Change display to grid
                  gridTemplateRows="1fr auto" // Set the first row to take remaining space, and the second row to auto
                >
                  <Box
                    w={{ base: "100px", sm: "180px", lg: "250px" }}
                    pb="10px"
                    justifyContent="center"
                    display="flex"
                    className="row"
                  >
                    <Image
                      display="flex"
                      alignSelf="center"
                      maxH={{ base: "150px", sm: "200px", lg: "300px" }}
                      src={value.node.postPhoto.url}
                      alt="AcclaimedHW contact us"
                    />
                  </Box>
                  <Box
                    className="blog row "
                    background="#FFFFFF"
                    borderRadius="20% /30%"
                    borderColor="#beeb9e"
                    borderStyle="solid"
                    borderWidth="10px 7px 11px 7px"
                    minH="150px"
                    minW="100%"
                    textAlign="center"
                    justifySelf="center"
                    alignSelf="end"
                    padding="7px"
                  >
                    <Text
                      fontWeight="bold"
                      textAlign="center"
                      fontSize="xl"
                      overflow="hidden"
                      whiteSpace="normal"
                    >
                      {value.node.title}
                    </Text>
                  </Box>
                </GridItem>
              </Link>
            ))}
          </Grid>
        </Container>
      </main>
      <Footer />
    </>
  );
}

export const query = graphql`
  query {
    allContentfulBlog(filter: {}) {
      edges {
        node {
          id
          postPhoto {
            url
          }
          published
          slug
          title
          articleBody {
            raw
          }
        }
      }
    }
  }
`;
