import {
  Box,
  Container,
  HStack,
  Text,
  Code,
  VStack,
  Image,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import React from "react";
import NavBar from "../components/header";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
  renderRichText,
} from "gatsby-source-contentful/rich-text";

export type BlogType = {
  contentfulBlog: {
    title: string;
    published: string;
    postPhoto: {
      url: string;
    };
    articleBody: RenderRichTextData<ContentfulRichTextGatsbyReference>;
    updatedAt: Date;
    slug: string;
  };
};

export default function BlogDetail({ data }: { data: BlogType }) {
  const { title, articleBody, postPhoto, published, updatedAt, slug } =
    data.contentfulBlog;

  const options = {};

  return (
    <>
      <NavBar />
      <main>
        <div className="w-full" style={{ overflow: "visible" }}>
          <Container maxW={["lg", "7xl"]} pt="0" position="relative">
            <HStack w="full" justifyContent="center">
              <Box w={{ base: "100px", sm: "180px", lg: "250px" }}>
                <StaticImage
                  src="../images/norman-desk-laptop.png"
                  alt={`"AcclaimedHW Blog ${slug}"`}
                />
              </Box>
              <Text
                textAlign="center"
                fontWeight="bold"
                fontSize={{ base: "3xl", sm: "4xl", md: "5xl", lg: "8xl" }}
                textColor="black"
                fontFamily="'League Spartan', sans-serif"
              >
                BLOG
              </Text>
            </HStack>
          </Container>
          <Box w="full" h="10" background="#01a1ba" py="2"></Box>
        </div>
        <div
          className="w-full"
          style={{ overflow: "visible", marginBottom: "5px" }}
        >
          <VStack>
            <Container
              maxW={["lg", "7xl"]}
              pt="0"
              position="relative"
              minH="75vh"
              pb="10px"
            >
              <Grid
                templateAreas={`"blank title"
               "img text"
               `}
                templateRows="repeat(10, 1fr)"
                templateColumns="repeat(5, 2fr)"
                w="full"
                py="16"
                justifyContent="end"
                h="200px"
              >
                <GridItem colStart={0.5} area="blank"></GridItem>
                <GridItem colEnd={7} pb="10px" area="title" textAlign="center">
                  <Code
                    justifySelf="center"
                    colorScheme="white"
                    textAlign="center"
                    fontWeight={600}
                    fontSize={{ base: "2xl", sm: "xl", md: "2xl", lg: "4xl" }}
                    textColor="black"
                    fontFamily="'League Spartan', sans-serif"
                  >
                    {title}
                  </Code>
                  <Code
                    fontFamily="'League Spartan', sans-serif"
                    fontSize={"s"}
                    colorScheme="white"
                    pl="4"
                  >
                    Published: {published}
                  </Code>
                </GridItem>
                <GridItem area={"img"} rowSpan={1}>
                  <Image
                    maxH="300px"
                    src={postPhoto.url}
                    alt={`"AcclaimedHW Blog ${slug}"`}
                  />
                </GridItem>
                <GridItem pl="4" area={"text"} colEnd={8} rowSpan={8}>
                  {renderRichText(articleBody, options)}
                </GridItem>
              </Grid>
            </Container>
          </VStack>
        </div>
      </main>
      {/* <Footer /> */}
    </>
  );
}

export const query = graphql`
  query BlogDetailPage($slug: String) {
    contentfulBlog(slug: { eq: $slug }) {
      title
      published
      postPhoto {
        url
      }
      articleBody {
        raw
      }
      updatedAt
    }
  }
`;
