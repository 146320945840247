import { Textarea } from '@chakra-ui/react';
import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type TextAreaFieldProps = FieldWrapperPassThroughProps & {
  className?: string;
  registration: Partial<UseFormRegisterReturn>;
  placeholder?: string
};

export const TextAreaField = (props: TextAreaFieldProps) => {
  const { label, className, registration, error, placeholder, ...rest } = props;
  return (
    <FieldWrapper label={label} error={error}>
      <Textarea
        className={className}
        placeholder={placeholder ? placeholder : ''}
        {...registration}
        {...rest}
      />
    </FieldWrapper>
  );
};
