import * as React from "react"
import Footer from "../components/footer"
import NavBar from "../components/header"
import {SEO} from '../components/seo'
import {StaticImage} from "gatsby-plugin-image"
import * as z from 'zod';
import {
    Button,
    Text,
    Flex,
    Container,
    Box,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    Radio,
    Checkbox,
    CheckboxGroup,
    Stack,
    ListItem,
    OrderedList
} from "@chakra-ui/react"


const Complete = (props: any) => {

    return (
        <>
            <SEO title="Order Complete"
                 description="Acclaimed Home Warranty - Real Estate Client Protection"/>
            <NavBar/>
            <main>
                <Container maxW={['lg', '4xl']} mt="50px" mb="40px">
                    <Flex direction="column">
                        <div className="w-md-80 w-lg-50 text-center mx-md-auto">
                            <svg className="svg-icon"
                                 style={{"width": "25%", "height": "25%", "margin": "auto auto"}}
                                 viewBox="0 0 1024 1024">
                                <path
                                    d="M510.466 65.279c-246.435 0-446.211 199.771-446.211 446.2 0 246.425 199.776 446.217 446.211 446.217 246.43 0 446.206-199.792 446.206-446.216 0-246.429-199.776-446.201-446.206-446.201zM798.79 420.782L478.707 740.811c-6.53 6.574-17.17 6.574-23.664 0l-47.313-47.76c-6.49-6.554-17.164-17.272-23.653-23.886L274.008 553.483c-3.228-3.287-4.953-7.823-4.953-12.152 0-4.303 1.725-8.428 4.953-11.735l48.235-43.47c3.262-3.327 7.63-4.954 11.938-4.933a16.68 16.68 0 0 1 11.716 4.933l109.147 111.397c6.495 6.61 17.135 6.61 23.664 0l249.137-248.427c6.495-6.55 17.13-6.55 23.624 0l47.323 47.798c6.517 6.535 6.517 17.314-0.002 23.888z"
                                    fill="#05C697"/>
                            </svg>
                            <div className="mb-5 text-center" style={{"text-align": "center"}}>
                                <h1 className="h3 font-weight-medium">Your order is completed!</h1>
                                <p>Thank you for your order! Your order is being processed.
                                    You will receive an email confirmation when your order is completed.</p>
                            </div>
                        </div>
                    </Flex>
                </Container>
            </main>
            <Footer/>
        </>
    )
}

export default Complete
