import * as React from "react"
import Footer from "../components/footer"
import NavBar from "../components/header"
import { SEO } from '../components/seo'
import { StaticImage } from "gatsby-plugin-image"
import { Form } from "../components/forms/Form"
import * as z from 'zod';
import toast from 'react-hot-toast';
import { Button, Text, Flex, Container, Box, HStack } from "@chakra-ui/react"
import { InputField } from "../components/forms/InputField"
import { TextAreaField } from "../components/forms/TextareaField"
import {
  useNetlifyForm,
  NetlifyFormProvider,
  Honeypot
} from 'react-netlify-forms'

const phoneRegexp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g
const schema = z.object({
  name: z.string().min(1),
  phone: z.string().regex(phoneRegexp, { message: 'Not valid phone number' }),
  email: z.string().email(),
  message: z.string()
});

type ContactUsValues = {
  name: string;
  phone: string;
  email: string;
  message: string;
};
const ContactUs = (props: any) => {

  const netlify = useNetlifyForm({
    name: 'contact',
    honeypotName: 'bot-field',
    onSuccess:() => {
      toast.success('Successfully submitted')
    }
  })
  const onSubmit = (data: ContactUsValues) => {netlify.handleSubmit(null, {...data, subject: 'Contact Request from Acclaimed Home warranty'})};

  return (
    <>
      <SEO title="Contact Us" description="Acclaimed Home Warranty" />
      <NavBar />
      <main>
        <div className="w-full" style={{ overflow: "visible" }}>
          <Container maxW={['lg', '8xl']} pt="12" position="relative">
            <HStack w="full" justifyContent="center">
              <Box w={{ base: "50px", md: "100px", lg: "200px" }}>
                <StaticImage src="../images/phone.png" alt="" />
              </Box>
              <Text fontSize={{ base: '3xl', sm:'4xl', md: '5xl', lg: '8xl' }} textColor="#01a1ba" fontWeight="bold" fontFamily="'League Spartan', sans-serif">Contact Us</Text>
              <Box w={{ base: "200px", md:'240px', lg: "280px" }} pl={{ base: '50px', md: "140px" }} position="relative">
                <Box pos="absolute" w={{ base: '120px', md:'140px', lg: "160px" }} left={{base:"-20px", md: "50px"}} top="-30px" zIndex="0">
                  <StaticImage src="../images/speech-bubble.svg" layout="fullWidth" alt="" />
                  <Text pos="absolute" top="10px" left="15px" fontSize={{ base: 'xs', md:'sm', lg: "md" }} textAlign="center">
                    Don't get hung <br />
                    up on <br />
                    unexpected <br />
                    Costs!
                  </Text>
                </Box>
                <Box w={{base:'100px', md:'200px', lg:"250px"}}>
                  <StaticImage src="../images/norman-phone-cord.png" alt="" />
                </Box>
              </Box>
            </HStack>
          </Container>
          <Box w="full" h="10" background="#01a1ba" py="2">
          </Box>
        </div>

        <Container maxW={['lg', '6xl']} mt="20px" mb="40px">
          <Flex direction="column">
            <Text textAlign="center">Have a question about our products and services? Fill out this short form and an Acclaimed Home Warranty representative will follow up on your request.</Text>
            <Text textAlign="center">Want to chat? Click the "<b>Text Us</b>" button at the bottom right of your screen. Prefer to talk? You can call us at <b>888.494.9460</b>.</Text>
          </Flex>
        </Container>
        <NetlifyFormProvider {...netlify} >
          <Form<ContactUsValues, typeof schema>
            onSubmit={onSubmit}
            schema={schema}
            isNetlify={true}
            formName="contact"
            dataNetlifyHoneypot="bot-field"
            subject="Contact Request from Acclaimed Home Warranty"   
          >
            {({ register, formState }) => (
              <>
                <Container maxW="lg">
                  <Honeypot />
                  <Flex direction="column" gap="20px" mb="20px">
                    <InputField isRequired label="Name" error={formState.errors['name']} registration={register('name')} placeholder="Please input your name" className="bg-lightgray"/>
                    <InputField isRequired label="Phone" error={formState.errors['phone']} registration={register('phone')} placeholder="+1 234567890" className="bg-lightgray"/>
                    <InputField isRequired label="Email" error={formState.errors['email']} registration={register('email')} placeholder="Please input your email address"  className="bg-lightgray" />
                    <TextAreaField label="Message" error={formState.errors['message']} registration={register('message')} className="bg-lightgray"/>

                    <Button type="submit" colorScheme='lightgreen' variant='solid' w="xs" alignSelf='center'>
                      Submit
                    </Button>
                  </Flex>
                </Container>

              </>
            )}
          </Form>
        </NetlifyFormProvider>
        
      </main>
      <Footer />
    </>
  )
}

export default ContactUs
