import { Box, Button, Container, Flex, Grid, HStack, Text, VStack } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import NavBar from '../components/header';
import { SEO } from "../components/seo";
import * as z from 'zod';
import { Form } from '../components/forms/Form';
import { InputField } from '../components/forms/InputField';
import Footer from '../components/footer';
import toast from 'react-hot-toast';

import {
  useNetlifyForm,
  NetlifyFormProvider,
  Honeypot
} from 'react-netlify-forms'

const schema = z.object({
  yourName: z.string().min(1),
  yourContract: z.string().min(1),
  yourAddress: z.string().min(1),
  referralName: z.string().min(1),
  referralContract: z.string().min(1),
  referralAddress: z.string().min(1),

})

type ReferralFriendValues = {
  yourName: string;
  yourContract: string;
  yourAddress: string;
  referralName: string;
  referralContract: string;
  referralAddress: string;
};

const ReferFriendPage = (props: any) => {
  const netlify = useNetlifyForm({
    name: 'refer-a-friend',
    honeypotName: 'bot-field',
    onSuccess:() => {
      toast.success('Successfully submitted')
    }
  })

  const onSubmit = (data: ReferralFriendValues) => { netlify.handleSubmit(null, {...data, subject: 'Refer a friend request from Acclaimed Home warranty'})};

  return (
    <>
      <SEO title="Refer a Friend | Acclaimed Home Warranty"
        description="Acclaimed Home Warranty - Refer a friend"
      />
      <NavBar />
      <main>
        {/** Banner */}
        <div className="w-full" style={{ overflow: "visible" }}>
          <Container maxW={["xl", "8xl"]} pt="12" position="relative" minH={{ base:"180px", md: "270px"}} fontFamily="Montserrat, sans-serif" >
            <HStack w="full" justifyContent="center" alignItems="baseline">
              <Box w="200px" h="200px" display={{ base: "none", sm: "block" }} background="#81e1e5" borderRadius="100%" position="absolute" bottom="-40px" left="0px" zIndex="-100">
              </Box>
              <Text fontFamily="'League Spartan', sans-serif" fontWeight="bold" textAlign="center" fontSize={{ base: '3xl', sm:'4xl', md: '5xl', lg: '8xl' }} textColor="#beeb9e">Refer A Friend</Text>
              <Box display={{ base: "flex", sm: "flex" }} w={{base: "200px", md:"300px"}} h={{base:"160px", md:"270px"}} background="#81e1e5" borderRadius="100%" 
                alignItems="flex-end"
                justifyContent="center"
                position="absolute" 
                bottom="-40px" 
                right="0"
                zIndex="-100"
              >
                <Box
                w={{base:'200px', md:"300px", lg:"300px", xl:'400px'}}
                bottom={{base: '15px', xl:'5px'}}
                 style={{
                  position: "absolute",                  
                  
                }}>
                  <StaticImage src="../images/norman-referral.png" alt="AcclaimedHW Refer a Friend" />
                </Box>
              </Box>

            </HStack>
          </Container>
          <Box w="full" h={{base: '14', md: "12"}} background="#beeb9e" py="2">
            <Text textAlign="center" fontSize={{base:'md', md: "xl"}}>
              Invite your friends to join the Acclaimed Home Warranty Family!
            </Text>
          </Box>
        </div>
        <Container maxW={["xl", "7xl"]} mt="20px" mb="40px">
          <Flex direction="column">
            <Text textAlign="center" mb={8}>
              Receive $50.00 of your renewal when you refer a friend to Acclaimed Home Warranty! But that's not
              all, your friend will receive a $25.00 credit on their account! Fill out the form below once your referral is
              signed up to claim your benefits!
            </Text>
          </Flex>
          <NetlifyFormProvider {...netlify} >
          <Form<ReferralFriendValues, typeof schema>
            onSubmit={onSubmit}
            schema={schema}
            isNetlify={true}
            formName="refer-a-friend"
            dataNetlifyHoneypot="bot-field"
            subject="Refer a friend request from Acclaimed Home warranty"   
          >
            {({ register, formState }) => (
              <>
                <Honeypot />
                <Grid templateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(2, 1fr)" }} gap={6} mt="3">
                  <Box marginX="4">
                    <Text display="block" w="280px" margin="0 auto" pb="1" borderRadius="3xl" textAlign="center" fontSize="2xl" px="4" background="#01a1ba"> Your Information </Text>
                    <Flex direction="column" gap="20px">
                      <InputField isRequired label='Name' error={formState.errors['yourName']} registration={register('yourName')} className="bg-lightgray"/>
                      <InputField isRequired label='Contract #' error={formState.errors['yourContract']} registration={register('yourContract')} className="bg-lightgray"/>
                      <InputField isRequired label='Address' error={formState.errors['yourAddress']} registration={register('yourAddress')} className="bg-lightgray" />
                    </Flex>
                  </Box>
                  <Box marginX="4">
                    <Text display="block" w="280px" margin="0 auto" pb="1" borderRadius="3xl" textAlign="center" fontSize="2xl" px="4" background="#01a1ba"> Referral's Information </Text>
                    <Flex direction="column" gap="20px">
                      <InputField isRequired label='Name' error={formState.errors['referralName']} registration={register('referralName')} className="bg-lightgray"/>
                      <InputField isRequired label='Contract #' error={formState.errors['referralContract']} registration={register('referralContract')} className="bg-lightgray"/>
                      <InputField isRequired label='Address' error={formState.errors['referralAddress']} registration={register('referralAddress')} className="bg-lightgray"/>
                    </Flex>
                  </Box>
                </Grid>
                <Flex alignContent="center" justifyContent="center">
                  <Button type='submit' mt={8} colorScheme="lightgreen" variant="solid" alignSelf="center" w="32"> Submit </Button>
                </Flex>
              </>
            )}
          </Form>
          </NetlifyFormProvider>
          
        </Container>
      </main>
      <Footer />
    </>
  )
}

export default ReferFriendPage;