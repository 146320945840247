import { axios } from "../../lib/axios";
import { useMutation } from "react-query";

export type CreateClaimDTO = {
  name: string;
  phone_number: string;
  email: string;
  street_address: string;
  city: string;
  state: string;
  zip_code: string;
  description: string;
  is_emergency: boolean;
  contact_methods: string;
  technicians: string;
};

const createClaim = (data: CreateClaimDTO): Promise<any> => {
  return axios.post("/claims", data);
};

export const useCreateClaim = () => {
  return useMutation({
    mutationFn: createClaim,
  });
};
