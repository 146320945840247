import { Container, VStack, Text, Grid, Link, Img } from "@chakra-ui/react"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Footer from "../components/footer"
import NavBar from "../components/header"
import { SEO } from "../components/seo"
import { DocumentSVG } from "../components/svg"
import ArizonaImage from '../images/state-images/Arizona.jpg'
import UtahImage from '../images/state-images/Utah.jpg'
import NevadaImage from '../images/state-images/Nevada.jpg'
import TexasImage from '../images/state-images/Texas.jpg'
import IdahoImage from '../images/state-images/Idaho.jpg'

const testimonialsMockData = [
  {
    name: "Shane",
    company: 'As Isz Services',
    quote: 'Working with Acclaimed home warranty is great. The organization is very professionally and truly cares about its customers. They want the best contractors they can find to make sure they are delivering the best to their customers. Acclaimed Home Warranty is hands down, a top notch organization.',
    image: "https://images.ctfassets.net/8baje2n8ckk1/4MMVYdpCr1472mDOxeogZh/6881031d442dc7a6a318d8e0594ee29f/asiszservices.30a26798.png?h=250"
  },
  {
    name: "Jake Harris",
    company: "SilverStar Electric",
    quote: "Our experience with Acclaimed always gives excellent communication, allowing us to do our job fast and efficiently. In all aspects of our business with Acclaimed, from start to finish, they are easy to work with. I'd also like to note that Acclaimed also pays invoices in a timely manner. We love working with Acclaimed Home Warranty and hope to continue a long business relationship. Thanks again for the opportunity to work with you.",
    image: "https://images.ctfassets.net/8baje2n8ckk1/785AryKQE3P2tGngyj5ARF/d344e3552c362c5bd25c935b04b0efd8/silverstar-electric.jpeg?h=250"
  }
]

const TestimonialsPage = (props: any) => <>
  <SEO title="Brochures | Acclaimed Home Warranty"
    description="Acclaimed Home Warranty - Brochures" />
  <NavBar />
  <main>
    <StaticImage src="../images/make-claim-banner.png" layout="fullWidth" alt="Acclaimed Brochures" />
    <Container maxW={['lg', '6xl']} mt="20px" mb="40px">
      <VStack spacing="20px">
        <Text fontWeight="bold" fontSize="5xl">
          Testimonials
        </Text>
      </VStack>
    </Container>
    <Container maxW={['lg', '6xl']} mt="20px" mb="40px">
      <Grid templateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(2, 1fr)" }} gap={6} mt="3">
        {testimonialsMockData.map((data) => (
          <VStack key={data.name}>
            <Img src={data.image} h="48" />
            <Text textAlign="center" pb="8" fontSize='2xl'>
              {data.company} | {data.name}
            </Text>
            <Text textAlign="center" pb="8">
              {data.quote}
            </Text>
          </VStack>
        ))}

      </Grid>
    </Container>
  </main>
  <Footer />
</>

export default TestimonialsPage