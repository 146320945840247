import * as React from "react"
import Footer from "../components/footer"
import NavBar from "../components/header"
import { SEO } from '../components/seo'
import { StaticImage } from "gatsby-plugin-image"
import { Form } from "../components/forms/Form"
import * as z from 'zod';
import toast from 'react-hot-toast';
import { Button, Text, Flex, Container, Box, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, Radio, Checkbox, CheckboxGroup, Stack, ListItem, OrderedList } from "@chakra-ui/react"
import { InputField } from "../components/forms/InputField"
import { TextAreaField } from "../components/forms/TextareaField"
import { useContactUs } from "../services/api/contact-us"
import { SelectField } from "../components/forms/SelectField"
import { RadioField } from "../components/forms/RadioField"
import { useRenewals } from "../services/api/renewals"
import claimVideoImage from "../images/claim-video-image.jpeg"

const phoneRegexp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g
const schema = z.object({
  first_name: z.string().min(1),
  last_name: z.string().min(1),
  home_address_or_policy_number: z.string().min(1),
  email: z.string().email(),
  phone: z.string().regex(phoneRegexp, { message: 'Not a valid phone number' }),
  is_renew_same: z.string(),
  other: z.string().optional(),
});

type RenewalsOrderDTO = {
  first_name: string;
  last_name: string;
  home_address_or_policy_number: string;
  email: string;
  phone: string;
  is_renew_same: string;
  other?: string;
};

const RenewalsPage = (props: any) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isOtherService, setOtherService] = React.useState(false)

  const createRenewalsMutation = useRenewals();

  return (
    <>
      <SEO title="Make a Claim | Acclaimed Home Warranty" description="Acclaimed Home Warranty - Make a Claim" />
      <NavBar />
      <main>
        {/** VIDEO PLAY MODAL */}
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <iframe width="100%" height="400px" src="https://www.youtube.com/embed/1mzkbJSIMHc"></iframe>
            </ModalBody>
          </ModalContent>
        </Modal>

        <StaticImage src="../images/make-claim-banner.png" layout="fullWidth" alt="AcclaimedHW contact us" />
        <Container maxW={['lg', '4xl']} mt="20px" mb="40px">
          <Flex direction="column">
            <Text fontSize='5xl' textAlign="center">MAKE A CLAIM</Text>
            <Text textAlign="center" fontSize="xl">Looking to renew your policy with our company? this page is for homeowner's that currently or had a policy with acclaimed home warranty. If you are renewing from another company feel free to “chat” in we are happy to help you there.
              <br />
              <br />
              For Acclaimed policy holders, please review renewal options below..</Text>

            <OrderedList>
              <ListItem>
                You can login to your account (upper right corner) and renew there
              </ListItem>
              <ListItem>
                You can chat with us M-F 8-6 MST
              </ListItem>
              <ListItem>
                You can call into 888-494-9460 dial 4 (M-F 8-6 MST)
              </ListItem>
              <ListItem>
                You can fill out this form below and we will quickly get back with you
              </ListItem>
            </OrderedList>

            <Box w="full" padding="0" margin="10px auto" zIndex="100">
              <Flex minH="500px" alignItems="center" justifyContent="center" backgroundSize="cover" position="relative" backgroundAttachment="fixed" backgroundImage={`url('${claimVideoImage}')`}
                _before={{
                  content: `""`,
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  bg: "gray.900",
                  opacity: "0.6",
                }}
              >
              </Flex>
            </Box>

            <Form<RenewalsOrderDTO, typeof schema>
              onSubmit={async (values: RenewalsOrderDTO) => {
                await createRenewalsMutation.mutateAsync({
                  ...values,
                  is_renew_same: values.is_renew_same === 'true'
                })

                if (createRenewalsMutation.isSuccess) {
                  toast.success('Successfully submitted your renewal order')
                }
              }}
              style={{ zIndex: 200 }}
              schema={schema}
            >
              {({ register, formState }) => (
                <>
                  <Container maxW={{ base: 'sm', lg: 'xl' }} zIndex="200" marginTop="-60px" background="#fff" paddingY="40px" paddingX="20px" borderRadius="10px" boxShadow="0 0 3px #dfdfdf">
                    <Flex direction="column" gap="20px" mb="20px">
                      <Text textAlign="justify" fontSize="sm">Otherwise, Please Tell Us About The Issue(S) By Filling Out The Form Below. <br /> A Service Fee Will Be Required.</Text>
                      <InputField label="First name" isRequired error={formState.errors['first_name']} registration={register('first_name')} placeholder="Please input your first name" />
                      <InputField label="Last name" isRequired error={formState.errors['last_name']} registration={register('last_name')} placeholder="Please input your last name" />
                      <InputField label="Home address or policy number" isRequired error={formState.errors['home_address_or_policy_number']} registration={register('home_address_or_policy_number')} />
                      <InputField label="Email address" isRequired error={formState.errors['email']} registration={register('email')} placeholder="Please input your email address" />
                      <InputField label="Phone number" isRequired error={formState.errors['phone']} registration={register('phone')} placeholder="+1 234567890" />

                      {/** Emergency? */}
                      <Text fontWeight="bold">Is this an emergency?*</Text>
                      <RadioField registration={register('is_renew_same')} error={formState.errors['is_renew_same']} defaultValue="true">
                        <Radio value='true'>I want to renew on the same plan & options as last year?</Radio>
                        <Radio value='false'>I want information on other renewal options & specials?</Radio>
                      </RadioField>

                      {/** Type of Technician(s) Required */}
                      <Text>Payment: if you choose to renew on the same plan as last year we will email you an invoice to securely pay online. Once payment is made you will receive the updated contract information to your email to securely pay online.</Text>

                      <InputField label="Anything else we can help you with today?" error={formState.errors['other']} registration={register('other')} />
                      <Button isLoading={createRenewalsMutation.isLoading} type="submit" colorScheme='gray' variant='solid' w="xs" alignSelf='auto'>
                        Submit
                      </Button>
                    </Flex>
                  </Container>

                </>
              )}
            </Form>
          </Flex>
        </Container>


      </main>
      <Footer />
    </>
  )
}

export default RenewalsPage
