import React, { useState } from "react";
import {
    Box,
    Text,
    VStack,
    useBreakpointValue,
    Heading,
    useColorModeValue,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    useDisclosure,
    Button,
    Stack,
    Icon,
    HStack,
} from "@chakra-ui/react";
import { navigate } from "gatsby";
import { IoClose } from "react-icons/io5";
import MapSVG from "./MapSVG";
import "./map.scss";
import { StaticImage } from "gatsby-plugin-image";

const LocationsMap = ({ locations, allFeatures, sortedOptions }) => {
    const isMobile = useBreakpointValue({ base: true, md: false });
    const [activeLocation, setActiveLocation] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const showLocation = (state) => {
        const location = locations.find((loc) => loc.slug === state);
        setActiveLocation(location);
        if (isMobile) {
            onOpen();
        }
    };

    const hideLocation = () => {
        setActiveLocation(null);
        if (isMobile) {
            onClose();
        }
    };

    const bgColor = useColorModeValue("white", "gray.800");
    const textColor = useColorModeValue("gray.800", "white");

    const renderLocationContent = (location) => (
        <Box
            maxW={"100%"}
            w={"full"}
            bg={bgColor}
            boxShadow={"xl"}
            rounded={"md"}
            overflow={"hidden"}
            position="relative"
        >
            <Icon
                as={IoClose}
                position="absolute"
                top={2}
                right={2}
                cursor="pointer"
                onClick={hideLocation}
                zIndex={2}
                boxSize={6}
            />
            <Box
                h={"120px"}
                bg={"gray.100"}
                pos={"relative"}
                backgroundImage={`url(${location.locationBackground.file.url})`}
                backgroundSize="cover"
                backgroundPosition="center"
            ></Box>
            <Stack spacing={4} p={4}>
                <Text
                    color={"green.500"}
                    textTransform={"uppercase"}
                    fontWeight={800}
                    fontSize={"sm"}
                    letterSpacing={1.1}
                >
                    {location.locationName}
                </Text>
                <Heading fontSize={"xl"} fontFamily={"body"}>
                    Lower Your Risk of Home Ownership
                </Heading>
                <Text color={"gray.600"} fontSize={"sm"}>
                    When you buy a home in {location.locationName}, you are investing in your life. But with
                    this investment comes many unknowns. When you are protected with a Home Warranty, Acclaimed
                    has you covered.
                </Text>
                <Stack direction={"column"} spacing={3}>
                    <Button
                        onClick={() =>
                            (window.location.href = `/homeowner-plans/${location.slug}`)
                        }
                        colorScheme="blue"
                        size="lg"
                        w="full"
                    >
                        Existing Homeowner Plans
                    </Button>
                    <Text color={"gray.500"} fontSize={"xs"} textAlign="center">
                        I'm ordering a plan for a home I currently reside in.
                    </Text>
                    <Button
                        onClick={() => {
                            navigate("/real-estate-order", {
                                state: {
                                    locationName: location.locationName,
                                },
                            });
                        }}
                        colorScheme="green"
                        size="lg"
                        w="full"
                    >
                        Real Estate Transactions
                    </Button>
                    <Text color={"gray.500"} fontSize={"xs"} textAlign="center">
                        I'm ordering a warranty for a home that is being purchased as part of a real estate
                        transaction.
                    </Text>
                </Stack>
            </Stack>
        </Box>
    );

    return (
        <>
            {/* Mobile-specific Image */}

            <HStack alignItems="flex-start" spacing={4}>
                {/* Desktop-specific Image */}
                {!isMobile && (
                    <Box
                        w={{ base: "200px", lg: "350px", xl: "550px" }}
                        display={{ base: "none", sm: "block", md: "block" }}
                    >
                        <StaticImage
                            src="../../images/select-state.png"
                            alt="Select a State"
                            className="select-state-image"
                            style={{
                                width: "100%",
                                height: "auto",
                            }}
                        />
                    </Box>
                )}

                {/* Map Container */}
                <Box
                    flex={1}
                    py={{ base: "20px", lg: "40px" }}
                    style={{
                        display: "block",
                        position: "relative",
                    }}
                >
                    <HStack alignItems="flex-start" pl={{ base: "0px", lg: "60px" }} spacing={4}>

                        <Box w="70px">
                            <StaticImage src="../../images/acclaimedHW.png" alt="Acclaimed Hardware logo" />
                        </Box>



                        <VStack alignItems="flex-start" spacing={1}>
                            <Text fontSize={{ base: "md", lg: "xl" }} lineHeight="1.5">
                                We are proudly serving our customers
                            </Text>
                            <Text fontSize={{ base: "md", lg: "xl" }} lineHeight="1.5">
                                in Arizona, Idaho, Utah, Nevada, and
                            </Text>
                            <Text fontSize={{ base: "md", lg: "xl" }} lineHeight="1.5">
                                Texas!
                            </Text>
                        </VStack>

                    </HStack>


                    {/* Enlarged MapSVG */}
                    <Box mt={6} w="full">
                        <MapSVG
                            showLocation={showLocation}
                            style={{
                                width: "100%",
                                height: isMobile ? "400px" : "600px", // Increase height for larger displays
                            }}
                        />
                    </Box>

                    {/* Modal for Mobile and Info Box for Desktop */}
                    {isMobile ? (
                        <Modal
                            isOpen={isOpen}
                            onClose={hideLocation}
                            size="full"
                            motionPreset="slideInBottom" // Adding some animation to make it look smoother
                            closeOnOverlayClick={isMobile}
                        >
                            <ModalOverlay backdropFilter="blur(10px)" bg="rgba(0, 0, 0, 0.8)" />
                            <ModalContent
                                position="fixed"
                                top="5%" // Ensures there's some margin from the top of the screen
                                m="0"
                                p={0}
                                borderRadius="lg"
                                boxShadow="xl"
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-start"
                                height="auto"
                                maxHeight="90vh" // Prevents modal from exceeding viewport height
                                overflowY="auto" // Allows scrolling if the content exceeds the height
                                bg="transparent"
                            >
                                <ModalBody p={0} width="90%" maxWidth="400px">
                                    {activeLocation && renderLocationContent(activeLocation)}
                                </ModalBody>
                            </ModalContent>
                        </Modal>
                    ) : (
                        activeLocation && (
                            <Box className="location-info">{renderLocationContent(activeLocation)}</Box>
                        )
                    )}
                </Box>
            </HStack>
        </>
    );
};

export default LocationsMap;
