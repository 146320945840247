// src/@chakra-ui/gatsby-plugin/theme.js
import { extendTheme } from "@chakra-ui/react";
const theme = {
  colors: {
    primary: "rebeccapurple",
    green: {
      700: "#beeb9e",
    },
    lightgreen: {
      50: "#effce4",
      100: "#d5f2be",
      200: "#beeb9e",
      300: "#a4e06c",
      400: "#95d844",
      500: "#85be2b",
      600: "#6f9421",
      700: "#556a16",
      800: "#363f0a",
    },
  },
};

export default extendTheme(theme);
